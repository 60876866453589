// React Required
import React, { Component, lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ProSidebarProvider } from "react-pro-sidebar";

// Create Import File
import './index.scss';

import PageScrollTop from './component/PageScrollTop';

// Dark Home Layout 
import DarkPortfolioLanding from './dark/PortfolioLanding';

// Element Layout
import error404 from "./elements/error404";


// Blocks Layout
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
// import Dashboard from './home/Dashboard';
// import UserList from './home/UserList';
// import PaymentReq from './home/PaymentReq';
// import AdminPassword from './home/AdminPassword';
// import Carousel from './component/admin/Carousel';
// import Logo from './component/admin/Logo';
// import Video from './component/admin/Video';
// import Offers from './component/admin/Offers';
// import AvailableSites from './component/admin/AvailableSites';
// import Testimonials from './component/admin/Testimonials';
// import SideBar from './component/admin/SideBar';
// import AdminLogin from './component/admin/Login';
// import Sitemap from './home/Sitemap';

const Dashboard = lazy(() => import('./home/Dashboard'));
const UserList = lazy(() => import('./home/UserList'));
const PaymentReq = lazy(() => import('./home/PaymentReq'));
const AdminPassword = lazy(() => import('./home/AdminPassword'));
const Carousel = lazy(() => import('./component/admin/Carousel'));
const Logo = lazy(() => import('./component/admin/Logo'));
const Video = lazy(() => import('./component/admin/Video'));
const Offers = lazy(() => import('./component/admin/Offers'));
const AvailableSites = lazy(() => import('./component/admin/AvailableSites'));
const Testimonials = lazy(() => import('./component/admin/Testimonials'));
const SideBar = lazy(() => import('./component/admin/SideBar'));
const AdminLogin = lazy(() => import('./component/admin/Login'));
const Sitemap = lazy(() => import('./home/Sitemap'));

class Root extends Component {
    render() {
        return (
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <ProSidebarProvider>
                        <Suspense fallback={<div>Loading...</div>}>
                            <Switch>
                                <Route exact path={`/`} component={DarkPortfolioLanding} />
                                <Route exact path={'/sitemap'} component={Sitemap} />
                                {localStorage.getItem('currentUser') && <Route exact path={`/dashboard`} component={Dashboard} />}
                                {localStorage.getItem('currentUser') && <Route exact path={`/admin`} component={SideBar} />}
                                <Route exact path={`/admin/login`} component={AdminLogin} />
                                <Route exact path={`/admin/password`} component={AdminPassword} />
                                <Route exact path={`/admin/carousel`} component={Carousel} />
                                <Route exact path={`/admin/offers`} component={Offers} />
                                <Route exact path={`/admin/logo`} component={Logo} />
                                <Route exact path={`/admin/video`} component={Video} />
                                <Route exact path={`/admin/sites`} component={AvailableSites} />
                                <Route exact path={`/admin/testimonials`} component={Testimonials} />
                                <Route exact path={`/admin/users`} component={UserList} />
                                <Route exact path={`/admin/req`} component={PaymentReq} />
                                <Route path={`/404`} component={error404} />
                                <Route component={error404} />
                            </Switch>
                        </Suspense>
                    </ProSidebarProvider>
                </PageScrollTop>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.register();