import React, { Component } from "react";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';   
class Testimonial extends Component {
    render() {
        return (
            <div class="container-xl">
            <div class="row">
                <div class="col-lg-8 mx-auto">
                    <div id="myCarousel" class="carousel slide" data-ride="carousel">
                        <ol class="carousel-indicators">
                            <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
                            <li data-target="#myCarousel" data-slide-to="1"></li>
                            <li data-target="#myCarousel" data-slide-to="2"></li>
                            <li data-target="#myCarousel" data-slide-to="3"></li>
                            <li data-target="#myCarousel" data-slide-to="4"></li>
                            <li data-target="#myCarousel" data-slide-to="5"></li>
                            <li data-target="#myCarousel" data-slide-to="6"></li>
                        </ol>
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <div class="img-box"><img src="/assets/images/icons/p3.jpeg" alt="" /></div>
                                <p style={{ textAlign: 'justify' }} class="testimonial">
                                I've been using this vicky247 club for a few months now, and I'm thoroughly impressed. The odds are competitive, the interface is user-friendly, and my payouts are always timely. The site's commitment to confidentiality and security is evident, making it a trustworthy platform. Highly recommend!                                  </p>
                                <p class="overview"><b>Rohit S.</b> (Delhi)</p>
                            </div>
                            <div class="carousel-item">
                                <div class="img-box"><img src="/assets/images/icons/p4.jpg" alt="" /></div>
                                <p style={{ textAlign: 'justify' }} class="testimonial">vicky247 club has completely changed my experience with online betting. The live betting options are fantastic, and the in-play statistics are incredibly helpful. Unlike other sites I've tried, this one ensures complete transparency and security with every transaction. It's the best platform I've used so far.</p>
                                <p class="overview"><b>Priya R. </b>(Tamil Nadu)</p>
                            </div>
                            <div class="carousel-item">
                                <div class="img-box"><img src="/assets/images/icons/p1.webp" alt="" /></div>
                                <p style={{ textAlign: 'justify' }} class="testimonial">The variety of sports and events available for betting is outstanding. Whether it's cricket football, casino or card games, there's always something to bet on. Plus, the customer service is top-notch and always ready to help. I've had bad experiences with fraud on other sites, but this one has proven to be safe and reliable.</p>
                                <p class="overview"><b>Kunal P. </b>(Gujarat)</p>
                            </div>
                            <div class="carousel-item">
                                <div class="img-box"><img src="/assets/images/icons/p5.avif" alt="" /></div>
                                <p style={{ textAlign: 'justify' }} class="testimonial">I've tried several online gaming sites but this one stands out. The bonuses and promotions are generous, and the site is very secure. The payment process is straightforward and secure, giving me peace of mind. I feel confident placing my bets here and have had a great experience overall.</p>
                                <p class="overview"><b>Ananya S</b> (Karnataka)</p>
                            </div>
                            
                            <div class="carousel-item">
                                <div class="img-box"><img src="/assets/images/icons/p5.webp" alt="" /></div>
                                <p style={{ textAlign: 'justify' }} class="testimonial">What I love most about  vicky247 club is the live streaming feature. It makes it so much easier to follow the games and place informed bets. Other sites have left me worried about my personal information, but this site prioritizes confidentiality and security.</p>
                                <p class="overview"><b>Aman V. </b> (Uttar Pradesh)</p>
                            </div>
                            <div class="carousel-item">
                                <div class="img-box"><img src="/assets/images/icons/p6.avif" alt="" /></div>
                                <p style={{ textAlign: 'justify' }} class="testimonial">This is the most reliable and user-friendly site I've ever used. The deposit and withdrawal process is smooth, and the site offers plenty of payment options. I've faced issues with fraud on other platforms, but here, I feel completely secure and supported with timely customer service.</p>
                                <p class="overview"><b>Fatima K. </b>(Mumbai)</p>
                            </div>
                            <div class="carousel-item">
                                <div class="img-box"><img src="/assets/images/icons/p8.avif" alt="" /></div>
                                <p style={{ textAlign: 'justify' }} class="testimonial">The site's robust security measures and commitment to confidentiality sets it apart from the rest</p>
                                <p class="overview"><b>Michael B.  </b>(London)</p>
                            </div>  
                        </div>
                        <a class="carousel-control-prev" href="#myCarousel" data-slide="prev">
                            <i class="fa fa-angle-left"></i>
                        </a>
                        <a class="carousel-control-next" href="#myCarousel" data-slide="next">
                            <i class="fa fa-angle-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}
export default Testimonial;