import React, { useEffect, useState } from 'react';
import ScrollToTop from 'react-scroll-up';
import Helmet from "../component/common/Helmet";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import ServiceList from "../elements/service/ServiceList";
import { Parallax } from 'react-parallax';
import Particles from 'react-tsparticles';
import CounterOne from '../elements/counters/CounterOne';
import UserDataService from '../services/userService';
import BrandOne from "../elements/BrandTwo";
import Slider from 'react-slick';
import { slideSlick } from '../page-demo/script';
import ModalVideo from 'react-modal-video';
import { collection } from 'firebase/firestore';
import db from "../firebase";
import { Alert, Modal } from 'react-bootstrap';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Testimonial from "../elements/Testimonial";
import { FiCast } from "react-icons/all";
import { FiLayers } from "react-icons/all";
import { FiUsers } from "react-icons/all";
import { FaInstagram } from "react-icons/all";
import { FaTelegram } from "react-icons/all";
import { FaWhatsapp } from "react-icons/all";
import FAQ from '../elements/Faq';

const image1 = "/assets/images/bg/8.webp";
const image3 = "/assets/images/bg/pm.webp";

const ServiceListOne = [
    {
        icon: <FiCast />,
        title: 'Deposit Money',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <FiLayers />,
        title: 'Send Us Payment Screenshot',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <FiUsers />,
        title: 'Get Your ID',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
]


const PortfolioLanding = () => {

    const [phone1, setPhone1] = useState("");
    const [phone2, setPhone2] = useState("");
    const [carouselImages, setCarouselImages] = useState();
    let [siteVideo, setVideo] = useState()
    let [siteOffers, setOffers] = useState()
    // const [mobileCarouselImages, setMobileCarouselImages] = useState();
    const [instaLink, setInstaLink] = useState("");
    const [teleGramLink, setTelegramLink] = useState("");
    const [color1, setColor1] = useState("");
    const [color2, setColor2] = useState("");
    const [color3, setColor3] = useState("");

    const [showLogin, setShowLogin] = useState(false);

    const [message, setMessage] = useState({ display: false, msg: "", type: "" });

    const [logoImage, setLogoImage] = useState("");

    const [number, setNumber] = useState("");

    const [password, setPassword] = useState("");

    const [isVideoOpen, setIsVideoOpen] = useState(false);

    const [howItWorks, setHowItWorks] = useState({});

    const [youtubeId, setYoutubeId] = useState("");

    const [faqs, setFaqs] = useState([
        {
            question: "Are online gambling sites legal in India?",
            answer:
                "Despite not being fully regulated in most states, online gambling is allowed throughout most of India. There is no law that makes online betting explicitly an illegal activity. The legality of various betting and gaming activities depends on which state you are in.",
            open: true
        },
        {
            question: "How Long Will it Take To Withdraw My Winnings?",
            answer: "We try to issue your withdrawals as early as possible.",
            open: false
        },
        {
            question:
                "What Bonus do I get if I register?",
            answer: "We update all the offers on the website regularly, you can refer to that to know more.",
            open: false
        },
        {
            question:
                "How to register?",
            answer: "Step 1: Sign up on our Website.",
            answer2: "Step 2: Login with the credentials you have set up.",
            answer3: "Step 3: Once you are in, you will be asked to select which associated websites you wish to play on.",
            answer4: "Step 4: After selecting “Create ID and deposit money” button you will be asked to enter your username for that website and the amount you wish to deposit.",
            answer5: "Step 5: You will then get the option of selecting the mode of payment.",
            answer6: "Step 6: Once you have done the payment, you will see a “share the screenshot to whatsapp” button, wherein you will have to share the screenshot of the payment",
            answer7: "Step 7: Our team will help you further.",
            open: false
        }
    ]);

    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };

    const getVideo = async () => {
        const videoRef = collection(db, 'video');
        const dbService = new UserDataService();

        const data = await dbService.getAllData(videoRef);
        let tmpArray = [];

        data.forEach((doc) => {
            let obj = doc.data();

            obj.id = doc.id;
            tmpArray.push(obj);
        });
        if (tmpArray?.length === 1) {
            console.log(tmpArray[0]?.url);
            setVideo(tmpArray[0]?.url);
        }
    }

    const getOffers = async () => {
        const offersRef = collection(db, 'offers');
        const dbService = new UserDataService();

        const data = await dbService.getAllData(offersRef);
        let tmpArray = [];

        data.forEach((doc) => {
            let obj = doc.data();

            obj.id = doc.id;
            tmpArray.push(obj);
        });
        console.log(tmpArray);
        setOffers(tmpArray);
    }

    const getHowItWorks = async () => {
        const dbService = new UserDataService();

        const data = await dbService.getHowItWorks();

        data.forEach((doc) => {
            console.log("hiw >>> ", doc.data());
            setHowItWorks(doc.data());
        })
    }

    let details = navigator.userAgent;
    let regexp = /android|iphone|kindle|ipad/i;
    /* Using test() method to search regexp in details
    it returns boolean value*/
    let isMobileDevice = regexp.test(details);

    const getCarouselImages = async () => {
        const carouselRef = collection(db, 'carousel');
        const dbService = new UserDataService();

        const data = await dbService.getAllData(carouselRef);
        let tmpArray = [];

        data.forEach((doc) => {
            let obj = doc.data();

            obj.id = doc.id;
            tmpArray.push(obj);
        });
        console.log(tmpArray);
        tmpArray.sort((a, b) => { return a?.date - b?.date });
        setCarouselImages(tmpArray);

    }

    const getSocialLinks = async () => {
        try {
            // alert("in footer" + props.phone)
            const socialLinks = collection(db, 'social');
            const dbService = new UserDataService();
            const data = await dbService.getAllData(socialLinks);
            let tmpArray = [];
            data.forEach((doc) => {
                let obj = doc.data();
                obj.id = doc.id;
                tmpArray.push(obj);
            });

            console.log("socialArray >>> ", tmpArray);

            setInstaLink(tmpArray[0]?.social?.insta);
            setTelegramLink(tmpArray[0]?.social?.tele);

        } catch (err) {
            console.log(err);

        }
    }


    const getColors = async () => {
        try {
            // alert("in footer" + props.phone)
            const colorsRef = collection(db, 'colors');
            const dbService = new UserDataService();
            const data = await dbService.getAllData(colorsRef);
            let tmpArray = [];
            data.forEach((doc) => {
                let obj = doc.data();
                obj.id = doc.id;
                tmpArray.push(obj);
            });

            console.log("Colors >>> ", tmpArray);

            setColor1(tmpArray[0]?.color1);
            setColor2(tmpArray[0]?.color2);
            setColor3(tmpArray[0]?.color3);

        } catch (err) {
            console.log(err);
        }
    }

    const getlogo = async () => {
        const logoRef = collection(db, 'logo');
        const dbService = new UserDataService();

        const data = await dbService.getAllData(logoRef);
        let tmpArray = [];

        data.forEach((doc) => {
            let obj = doc.data();

            obj.id = doc.id;
            tmpArray.push(obj);
        });
        if (tmpArray?.length === 1) {
            console.log(tmpArray[0]?.url);
            setLogoImage(tmpArray[0]?.url);
        }
    }


    useEffect(async () => {
        const service = new UserDataService();
        const data = await service.getAllPaymentDetails();
        data.forEach((doc) => {
            console.log(doc.data());
            setPhone1(doc.data().phone1);
            setPhone2(doc.data().phone2);
        })
        getCarouselImages();
        getVideo();
        getOffers();
        getSocialLinks();
        getColors();
        getlogo();
        getHowItWorks();
    }, []);

    // useEffect(() => {
    //     const webImages = carouselImages;
    //     // const mobileImages = carouselImages?.filter((image) => {
    //     //     return image.carouselType == 'mobile';
    //     // })
    //     console.log(webImages)
    //     setWebCarouselImages(carouselImages)
    //     // console.log(mobileImages)
    //     // setMobileCarouselImages(mobileImages)
    // }, [carouselImages])

    useEffect(() => {

        const styleElement = document.createElement('style');
        let styleSheet = null;
        document.head.appendChild(styleElement);
        styleSheet = styleElement.sheet;

        const keyframesStyle = `
        @keyframes color-change {
            from {
              background-color: ${color1};
            }
            to {
              background-color: ${color2};
            }
          }
      `;
        styleSheet.insertRule(keyframesStyle, styleSheet.cssRules.length);
    }, [color1, color2])

    const inputStyleNo = {
        color: "#000000",
        borderRadius: 0,
        borderColor: color1,
        backgroundColor: 'white',
    }

    const inputStyle = {
        color: "#000000",
        borderRadius: 0,
        borderColor: color1,
        backgroundColor: 'white',
    }

    const handleCloseLogin = () => {
        setShowLogin(false)
    }

    const handleShowLogin = () => {
        setShowLogin(true)
    }

    const onLogin = async (e) => {
        e.preventDefault();
        setMessage({ message: { display: false, msg: "", type: '' } })
        const loginData = {
            number: number,
            password: password
        }
        const service = new UserDataService();

        try {
            let dbUser = {};
            // console.log(loginData);
            let user = await service.queryUserByPhone(loginData.number);

            console.log(user.size);
            user.forEach((doc) => {
                // console.log(doc.id, " => ", doc.data());
                if (doc.id) {
                    dbUser = {
                        userId: doc.id,
                        email: doc.data().email,
                        fullName: doc.data()?.fullName,
                        password: doc.data().password,
                        number: doc.data()?.number,
                    }

                    if (loginData.password != dbUser.password) {
                        setMessage({ display: true, msg: "Invalid password", type: 'danger' })
                        return;
                    }

                    localStorage.setItem('currentUser', JSON.stringify({
                        userId: doc.id,
                        email: doc.data().email,
                        fullName: doc.data().fullName,
                        password: doc.data().password,
                        number: doc.data().number,
                    }))
                    setShowLogin(false)
                    if (dbUser.email == "admin@betlights.com") {
                        window.location.replace('/admin/');
                        localStorage.setItem('isAdmin', 'true');
                        return
                    }
                    window.location.replace('/dashboard')
                    // console.log("navigation");
                }
            });
            // console.log(">>>>>>", dbUser)
            if (!message.display)
                setMessage({ display: true, msg: "User Doesn't Exist!", type: 'danger' })

        } catch (e) {
            console.log(e);
        }

    }

    const onVideoOpen = async (e) => {
        console.log(e);
        console.log(howItWorks);
        if (e.title == "Deposit Money") {
            setYoutubeId(howItWorks.deposit)
        } else if (e.title == "Get Your ID") {
            setYoutubeId(howItWorks.getid)
        } else {
            setYoutubeId(howItWorks.payment)
        }
        setIsVideoOpen(true)
    }

    return (
        <div className="active-light">
            <Helmet pageTitle="Vicky Club" />

            <HeaderThree phone={phone1} homeLink="/" logo="symbol-dark" color="color-black" />
            {/* Start Slider Area   */}

            <div id='home' className="slider-wrapper bg_color--5">
                {<div style={{ top: -60 }} className="slider-activation">
                    <Slider {...slideSlick}>
                        {carouselImages?.map((value, index) => (
                            // <div style={{ opacity: 1 }} className={`slide slide-style-2 slider-box-content without-overlay align-items-center bg_image ${value.bgImage}`} key={index}>
                            //     <div className="container">
                            //         <div className="row">
                            //             <div className="col-lg-12">
                            //                 <div className={``}>
                            //                 </div>
                            //             </div>
                            //         </div>
                            //     </div>
                            // </div>
                            <>
                                <>
                                    <div key={`${index}`} className={`slide slide-style-2 slider-box-content without-overlay align-items-center bg_image`}>
                                        <img style={{ opacity: 1 }}
                                            src={value?.url} alt={`${index}`} />
                                    </div>
                                </>
                            </>
                        ))}
                    </Slider>
                </div>}

                {/* {isMobileDevice && <div style={{ top: -60 }} className="slider-activation">
                    <Slider {...slideSlick}>
                        {mobileCarouselImages?.map((value, index) => (
                            // <div style={{ opacity: 1 }} className={`slide slide-style-2 slider-box-content without-overlay align-items-center bg_image ${value.bgImage}`} key={index}>
                            //     <div className="container">
                            //         <div className="row">
                            //             <div className="col-lg-12">
                            //                 <div className={``}>
                            //                 </div>
                            //             </div>
                            //         </div>
                            //     </div>
                            // </div>

                            <>
                                {value?.carouselType == 'mobile' && <>
                                    <div key={`${index}`} className={`slide slide-style-2 slider-box-content without-overlay align-items-center bg_image`}>
                                        <img style={{ opacity: 1 }}
                                            src={value?.url} alt={`${index}`} />
                                    </div>
                                </>}
                            </>

                        ))}
                    </Slider>
                </div>} */}

            </div>

            <div className="rn-featured-service-area bg_color--5">
                {/* <div className="container mt-5"> */}
                <div className="row text-center p-5">

                    <div className="container-fluid">


                        {siteOffers?.length > 0 && <div>

                            {siteOffers?.map((offer, index) => {
                                return (<>
                                    <img className='p-1 col-xl-6 col-md-6 col-lg-6 col-sm-12 col-12' src={offer?.url} alt="offer image" />
                                </>)
                            })}
                        </div>}

                        {/* <img onClick={() => {
                            window.open(`https://api.whatsapp.com/send?phone=${phone1}&text=Hi! I want to get a free ID for 222`, "_blank");
                        }} src='/assets/images/featured/1.png' style={{ cursor: 'pointer' }}/> */}
                    </div>

                    {/* {!isMobileDevice && <div className="col-6">
                        <img src='/assets/images/featured/2.png'/>
                    </div>} */}

                    {/* {isMobileDevice && <div className="col-12">
                        <img onClick={() => {
                            window.open(`https://api.whatsapp.com/send?phone=${phone1}&text=Hi! I want to get a free ID for 222`, "_blank");
                        }} src='/assets/images/featured/1.png' style={{ cursor: 'pointer' }} />
                    </div>}

                    {isMobileDevice && <div className="col-12 mt-3">
                        <img src='/assets/images/featured/2.png' />
                    </div>} */}

                </div>
                <div className="row" style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <button style={{
                        backgroundColor: { color1 },
                        height: 90,
                        boxShadow: `0 0 20px 1px ${color1}`,
                        color: { color1 },
                        animation: `color-change 1s infinite`,
                    }} type="button" className="rj-btn mt-3 changeColor" onClick={() => {
                        window.open(`https://api.whatsapp.com/send?phone=${phone1}&text=Hi I want to get ID!`, "_blank");
                    }} >
                        <span style={{ fontWeight: 'bold', fontSize: 31 }}>Get ID</span>
                    </button>
                </div>
                {/* </div> */}
            </div>

            {/* <div className="slider-activation slider-creative-agency with-particles" id="home">
                <div className="bg_image bg_image--3">
                    <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                        <h2 style={{ color: '#18b0c8' }} className="title">Dealing Terms</h2>
                                        <p style={{ color: '#18b0c8' }}>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row service-one-wrapper">
                                {ServiceListTwo.map((val, i) => (
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-3" key={i}>
                                        <a className="text-center">
                                            <div className="service service__style--27">
                                                <div className="icon">
                                                    {val.icon}
                                                </div>
                                                <div className="content">
                                                    <h3 className="title">{val.title}</h3>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}


            {/* End Slider Area   */}

            {/* Start Service Area  */}
            <div id="available-sites" className="fix">
                <div className="service-area creative-service-wrapper ptb--120 bg_color--5" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                    <h2 style={{ color: color1 }} className="title">AVAILABLE SITES ON OUR PLATFORM</h2>
                                    <p style={{ color: color1 }}>Play on the leading websites and get unlimited rewards.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div style={{ backgroundColor: '' }} className="col-lg-12 text-center">
                                <ServiceList color1={color1} item="21" column="col-lg-4 col-md-6 col-sm-6 col-12 text-center" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Area  */}

            <div id='deal' style={{ position: 'relative', }}>
                <div id='video-container' style={{ zIndex: -1 }}>

                    <video autoPlay loop muted style={{ width: '100%', height: '50%' }} src={siteVideo}>
                        {!isMobileDevice && <source id='mp4' src={siteVideo} type='video/mp4' />}

                        {isMobileDevice && <source id='mp4' src='/assets/images/preview/BET.mp4' type='video/mp4' />}
                    </video>
                </div>
            </div>

            {/* <div className="rn-blog-area pt--120 pb--80 bg_color--1">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-12">
                            <div className="section-title service-style--3 text-center">
                                <h2 style={{ color: color1 }} className="title">OUR TESTIMONIALS</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row mt--60">
                        {PostList.map((value, i) => (
                            <div onClick={() => {
                                if (i == 2) {
                                    setIsOpen(true);
                                } else {
                                    window.open(`https://api.whatsapp.com/send?phone=${phone1}&text=Hi I want to get ID!`, "_blank");
                                }
                            }} style={{ cursor: 'pointer' }} className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                <div className="blog blog-style--1">
                                    <div className="thumbnail">
                                        <a>
                                            {i != 2 && <img className="w-100" src={`/assets/images/blog/blog-${value.images}.jpeg`} alt="Blog Images" />}
                                            {i == 2 && <img style={{ height: 530 }} className="w-100" src={`/assets/images/blog/blog-03.png`} alt="Blog Images" />}
                                        </a>
                                    </div>
                                    <div className="content">
                                        <a className="rn-btn text-white mb-5 mt-3" onClick={() => {
                                            window.open(`https://api.whatsapp.com/send?phone=${phone1}&text=Hi I want to get ID!`, "_blank");
                                        }} style={{ cursor: 'pointer', borderColor: 'white' }}>Read More</a>
                                        <div className="blog-btn">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        <ModalVideo channel='youtube' isOpen={isOpen} videoId='Rftd_fwWO08' onClose={() => setIsOpen(false)} />

                    </div>
                </div>
            </div> */}

            {/* Start Brand Area  */}
            <div className="rn-brand-area pb--60 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <BrandOne showLogin={handleShowLogin} color1={color1} color2={color2} branstyle="branstyle--2" />
                        </div>
                    </div>
                </div>
            </div>
            {/* End Brand Area  */}

            <Modal centered show={showLogin} onHide={() => handleCloseLogin()}>
                <Modal.Header style={{
                    backgroundImage: `linear-gradient(${color2},${color1})`,
                    borderBottomColor: color1,
                    alignContent: 'center',
                    justifyContent: 'center'
                }} closeButton>
                    <div className="text-center ml-7">
                        <img style={{ justifyContent: "center", alignItems: "center", marginLeft: isMobileDevice ? 60 : 120 }} height={130} width={'auto'} src={logoImage} alt="Digital Agency" />
                    </div>
                </Modal.Header>
                <form onSubmit={(e) => onLogin(e)}>
                    <Modal.Body style={{ backgroundColor: color1, top: 0 }}>
                        {message.display && <Alert variant={message.type}>
                            {message.msg}
                        </Alert>}
                        <div className="rn-form-group" style={inputStyleNo}>
                            <PhoneInput
                                defaultCountry="IN"
                                value={number}
                                onChange={phone => setNumber(phone)}
                                style={inputStyleNo}
                                type="text"
                                name="Number"
                                placeholder="Mobile Number"
                                required
                            />
                        </div>
                        <div className="rn-form-group mt-3">
                            <input
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                style={inputStyle}
                                type="password"
                                name="password"
                                placeholder="Password"
                                required
                            />
                        </div>
                        {/* <p className="mt-3" style={{ color: color3 }}>Don't Have an Account? <a style={{ color: color3, cursor: 'pointer' }} onClick={() => this.setState({ show: true, showLogin: false })}>Register</a> </p> */}
                        {/* <p className="mt-3" style={{ color: color3 }}> <a style={{ color: color3, cursor: 'pointer' }} onClick={() => this.setState({ showFwp: true, showLogin: false })}>Forgot Password</a> </p> */}
                    </Modal.Body>
                    <Modal.Footer style={{ backgroundColor: color1, borderTopColor: color1, alignContent: 'center', justifyContent: 'center' }}>
                        <button style={{ color: color3, borderColor: color3 }} type="submit" className="rn-btn">
                            <span>Login</span>
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>




            <Parallax className="rn-paralax-portfolio" bgImage={isMobileDevice ? image3 : image1} strength={200} >
                <div id="support" className="portfolio-area ptb--120" data-black-overlay="1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="">

                                    {isMobileDevice && <a className='mb-3' style={{
                                        color: '#fff',
                                        lineHeight: 1.5,
                                        fontSize: 36,
                                        fontWeight: 'bold'
                                    }}>• Fastest ⚡ withdraw <br />• 100 % SAFE NO LEGAL DOCUMENTATION <br /> • 24/7 HELP LINE IN 20 LANGUAGES
                                    </a>}

                                    {!isMobileDevice && <h2 style={{
                                        color: '#fff',
                                        // textShadow: '#1C74CB 0 0 6px'
                                    }}>• Fastest ⚡ withdraw <br />• 100 % SAFE NO LEGAL DOCUMENTATION <br /> • 24/7 HELP LINE IN 20 LANGUAGES
                                    </h2>}

                                </div>
                                <br />
                                <br />
                                <div className="section-title service-style--2 mb--30 mb_sm--0">
                                    <button style={{
                                        backgroundColor: color1,
                                        color: 'white',
                                        width: 330
                                    }} type="button" className="rj-btn" onClick={() => {
                                        window.open(`https://api.whatsapp.com/send?phone=${phone1}&text=Hi I want to get ID!`, "_blank");
                                    }} >
                                        <span style={{ fontWeight: 'bold', textShadow: '0 0 1px #000, 0 0 1px #000', fontSize: 21 }}>{phone1}</span>
                                    </button>
                                    <button style={{
                                        backgroundColor: color1,
                                        color: 'white',
                                        width: 330
                                    }} type="button" className={`rj-btn ${!isMobileDevice ? 'ml-3' : 'mt-3'}`} onClick={() => {
                                        window.open(`https://api.whatsapp.com/send?phone=${phone2}&text=Hi I want to get ID!`, "_blank");
                                    }}>
                                        <span style={{ fontWeight: 'bold', textShadow: '0 0 1px #000, 0 0 1px #000', fontSize: 21 }}>{phone2}</span>
                                    </button>
                                    <br />
                                    <br />
                                    <br />
                                    <h3 style={{
                                        color: '#fff',
                                        textShadow: `${color1} 0 0 20px`,
                                        lineHeight: 1.5
                                    }}>We Deal Directly On WhatsApp</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Parallax >

            <div className="counterup-area ptb--120 bg_color--5">
                <div className="container">
                    <CounterOne color1={color1} color2={color2} />
                </div>
            </div>


            {/* <Parallax className="rn-paralax-portfolio" bgImage={isMobileDevice ? image3 : image2} strength={500} >
                <div id='support' className="portfolio-area ptb--120" data-black-overlay="1">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-9"
                                style={{ textAlign: 'left' }}>

                                <div className="section-title service-style--2 mb--30 mb_sm--0">
                                    <h1 style={{
                                        color: '#fff',
                                        // textShadow: '#18b0c8 0 0 6px'
                                    }}>Customer Support
                                    </h1>
                                    <h3 style={{
                                        color: '#fff',
                                        // textShadow: '#18b0c8 0 0 3px',
                                        lineHeight: 1.2
                                    }}>Get in touch with BetLights customer care <br /> for any Queries, Emergencies, Feedbacks or <br /> Complaints. We are here to help 24/7 <br /> with our online services.
                                    </h3>
                                </div>
                                <br />
                                <div className="section-title service-style--2 mb--30 mb_sm--0">
                                    <br />
                                    <br />
                                    <h3 style={{
                                        color: '#fff',
                                        // textShadow: '#ee076e 0 0 30px'
                                    }}>Customer Care No. For WhatsApp & Calling</h3>
                                    <br />
                                    <button style={{
                                        backgroundColor: '#18b0c8'
                                    }} type="button" className="rj-btn" onClick={() => {
                                        window.open(`https://api.whatsapp.com/send?phone=${phone1}&text=Hi I want to get ID!`, "_blank");
                                    }} >
                                        <span style={{ fontWeight: 'bold', textShadow: '0 0 1px #000, 0 0 1px #000', fontSize: 21 }}>+916378934211</span>
                                    </button>

                                </div>
                            </div>
                            <div className="col-lg-3">

                            </div>
                        </div>
                    </div>
                </div>
            </Parallax > */}



            <div className="slider-activation slider-creative-agency with-particles" id="home">
                <Particles className="particle"
                    options={{
                        style: {
                            position: "absolute"
                        },
                        fpsLimit: 100,
                        interactivity: {
                            detectsOn: "canvas",
                            events: {
                                onClick: {
                                    enable: true,
                                    mode: "push",
                                },
                                onHover: {
                                    enable: true,
                                    mode: "repulse",
                                },
                                resize: true,
                            },
                            modes: {
                                bubble: {
                                    distance: 100,
                                    duration: 2,
                                    opacity: 0.8,
                                    size: 10,
                                    color: color1,
                                },
                                push: {
                                    quantity: 4,
                                },
                                repulse: {
                                    distance: 100,
                                    duration: 0.4,
                                    color: color1,
                                },
                            },
                        },
                        particles: {
                            color: {
                                value: color1,
                            },
                            links: {
                                color: color1,
                                distance: 150,
                                enable: true,
                                opacity: 0.6,
                                width: 1,
                            },
                            collisions: {
                                enable: true,
                            },
                            move: {
                                direction: "none",
                                enable: true,
                                outMode: "bounce",
                                random: false,
                                speed: 6,
                                straight: false,

                            },
                            number: {
                                density: {
                                    enable: true,
                                    value_area: 2000,
                                },
                                value: 80,
                            },
                            opacity: {
                                value: 0.5,
                            },
                            shape: {
                                type: "circle",
                            },
                            size: {
                                random: true,
                                value: 5,
                            },
                        },
                        detectRetina: true,
                    }}
                />
                <div className="bg_image bg_image--3">
                    {/* {SlideList.map((value, index) => ( */}
                    <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb_sm--0">
                                        <h2 style={{ color: color1 }} className="title">How It Works</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row service-one-wrapper">
                                <ModalVideo channel='youtube' isOpen={isVideoOpen} videoId={youtubeId} onClose={() => setIsVideoOpen(false)} />
                                {ServiceListOne.map((val, i) => (
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-3" key={i}>
                                        <a className="text-center">
                                            <div onClick={() => onVideoOpen(val)} style={{ cursor: 'pointer' }} className="service service__style--27">
                                                <div style={{ color: color1 }} className="icon">
                                                    {val.icon}
                                                </div>
                                                <div className="content">
                                                    <h3 style={{ color: color2 }} className="title">{val.title}</h3>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                            </div>

                        </div>

                    </div>
                    {/*  ))} */}
                </div>
            </div>

            <div className="row mb--30">
                {!isMobileDevice && <div className="col-12">

                    <div className="text-center">
                        <button onClick={() => {
                            window.open(`${teleGramLink}`, "_blank");
                        }} className='changeColor btn mr-5' id="myButton" style={{ height: 90, width: 600, fontWeight: 'bold', fontSize: 25, backgroundColor: { color1 }, animation: `color-change 1s infinite`, }}>JOIN US ON TELEGRAM <FaTelegram className='ml-2' size={`${isMobileDevice ? 35 : 40}`} fill="white" /></button>

                        <button onClick={() => {
                            window.open(`${instaLink}`, "_blank");
                        }} className='changeColor btn' id="myButton" style={{ height: 90, width: 600, fontWeight: 'bold', fontSize: 25, animation: `color-change 1s infinite`, backgroundColor: { color1 }, }}>JOIN US ON INSTAGRAM <FaInstagram className='ml-2' size={`${isMobileDevice ? 35 : 40}`} fill="white" /></button>
                    </div>

                </div>}

                {isMobileDevice && <div className="col-12 text-center">
                    <button onClick={() => {
                        window.open(`${teleGramLink}`, "_blank");
                    }} className='changeColor btn' id="myButton" style={{ height: 90, width: isMobileDevice ? 300 : 600, fontWeight: 'bold', fontSize: 25, animation: `color-change 1s infinite`, }}>JOIN US ON TELEGRAM <FaTelegram className='ml-2' size={`${isMobileDevice ? 35 : 40}`} fill="white" /></button>
                </div>}

                {isMobileDevice && <div className="col-12 text-center mt-3">
                    <button onClick={() => {
                        window.open(`${instaLink}`, "_blank");
                    }} className='changeColor btn' id="myButton" style={{ height: 90, width: isMobileDevice ? 300 : 600, fontWeight: 'bold', fontSize: 25, animation: `color-change 1s infinite`, }}>JOIN US ON INSTAGRAM <FaInstagram className='ml-2' size={`${isMobileDevice ? 35 : 40}`} fill="white" /></button>
                </div>}
            </div>

            <div className="rn-testimonial-area bg_color--5 ptb--60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="faqs bg_color--5">
                                <div className="section-title service-style--3 text-center mt--20">
                                    <h2 className="title">FAQs</h2>
                                    {/* <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p> */}
                                </div>
                                {faqs.map((faq, index) => (
                                    <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Start Testimonial Area */}
            <div className="rn-testimonial-area bg_color--1 ptb--90">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title service-style--3 text-center mb--100">
                                <h2 className="title">Testimonials</h2>
                                {/* <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p> */}
                            </div>
                        </div>
                    </div>
                    <Testimonial />
                </div>
            </div>
            {/* End Testimonial Area */}



            {/* <div id="carouselExampleControls" data-mdb-carousel-init className="carousel slide text-center carousel-dark" data-mdb-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img className="rounded-circle shadow-1-strong mb-4"
                            src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(10).webp" alt="avatar"
                            style={{width: 150}} />
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-8">
                                <h5 className="mb-3">Maria Kate</h5>
                                <p>Photographer</p>
                                <p className="text-muted">
                                    <i className="fas fa-quote-left pe-2"></i>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus et deleniti
                                    nesciunt sint eligendi reprehenderit reiciendis, quibusdam illo, beatae quia
                                    fugit consequatur laudantium velit magnam error. Consectetur distinctio fugit
                                    doloremque.
                                </p>
                            </div>
                        </div>
                        <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
                            <li><i className="fas fa-star fa-sm"></i></li>
                            <li><i className="fas fa-star fa-sm"></i></li>
                            <li><i className="fas fa-star fa-sm"></i></li>
                            <li><i className="fas fa-star fa-sm"></i></li>
                            <li><i className="far fa-star fa-sm"></i></li>
                        </ul>
                    </div>
                    <div className="carousel-item">
                        <img className="rounded-circle shadow-1-strong mb-4"
                            src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(32).webp" alt="avatar"
                            style={{width: 150}} />
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-8">
                                <h5 className="mb-3">John Doe</h5>
                                <p>Web Developer</p>
                                <p className="text-muted">
                                    <i className="fas fa-quote-left pe-2"></i>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus et deleniti
                                    nesciunt sint eligendi reprehenderit reiciendis.
                                </p>
                            </div>
                        </div>
                        <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
                            <li><i className="fas fa-star fa-sm"></i></li>
                            <li><i className="fas fa-star fa-sm"></i></li>
                            <li><i className="fas fa-star fa-sm"></i></li>
                            <li><i className="fas fa-star fa-sm"></i></li>
                            <li><i className="far fa-star fa-sm"></i></li>
                        </ul>
                    </div>
                    <div className="carousel-item">
                        <img className="rounded-circle shadow-1-strong mb-4"
                            src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp" alt="avatar" style={{width: 150}} />
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-8">
                                <h5 className="mb-3">Anna Deynah</h5>
                                <p>UX Designer</p>
                                <p className="text-muted">
                                    <i className="fas fa-quote-left pe-2"></i>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus et deleniti
                                    nesciunt sint eligendi reprehenderit reiciendis, quibusdam illo, beatae quia
                                    fugit consequatur laudantium velit magnam error. Consectetur distinctio fugit
                                    doloremque.
                                </p>
                            </div>
                        </div>
                        <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
                            <li><i className="fas fa-star fa-sm"></i></li>
                            <li><i className="fas fa-star fa-sm"></i></li>
                            <li><i className="fas fa-star fa-sm"></i></li>
                            <li><i className="fas fa-star fa-sm"></i></li>
                            <li><i className="far fa-star fa-sm"></i></li>
                        </ul>
                    </div>
                </div>
                <button data-mdb-button-init className="carousel-control-prev" type="button" data-mdb-target="#carouselExampleControls"
                    data-mdb-slide="prev">
                    <span className="carousel-control-prev-icon text-body" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button data-mdb-button-init className="carousel-control-next" type="button" data-mdb-target="#carouselExampleControls"
                    data-mdb-slide="next">
                    <span className="carousel-control-next-icon text-body" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div> */}

            {/* <section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
    <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex flex-col items-center">
            <div className="text-center">
                <p className="text-lg font-medium text-gray-600 font-pj">2,157 people have said how good Rareblocks</p>
                <h2 className="mt-4 text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl font-pj">Our happy clients say about us</h2>
            </div>

            <div className="mt-8 text-center md:mt-16 md:order-3">
                <a href="#" title="" className="pb-2 text-base font-bold leading-7 text-gray-900 transition-all duration-200 border-b-2 border-gray-900 hover:border-gray-600 font-pj focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2 hover:text-gray-600"> Check all 2,157 reviews </a>
            </div>

            <div className="relative mt-10 md:mt-24 md:order-2">
                <div className="absolute -inset-x-1 inset-y-16 md:-inset-x-2 md:-inset-y-6">
                    <div className="w-full h-full max-w-5xl mx-auto rounded-3xl opacity-30 blur-lg filter" style={{background: 'linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)'}}></div>
                </div>

                <div className="relative grid max-w-lg grid-cols-1 gap-6 mx-auto md:max-w-none lg:gap-10 md:grid-cols-3">
                    <div className="flex flex-col overflow-hidden shadow-xl">
                        <div className="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7">
                            <div className="flex-1">
                                <div className="flex items-center">
                                    <svg className="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path
                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                        />
                                    </svg>
                                    <svg className="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path
                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                        />
                                    </svg>
                                    <svg className="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path
                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                        />
                                    </svg>
                                    <svg className="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path
                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                        />
                                    </svg>
                                    <svg className="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path
                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                        />
                                    </svg>
                                </div>

                                <blockquote className="flex-1 mt-8">
                                    <p className="text-lg leading-relaxed text-gray-900 font-pj">“You made it so simple. My new site is so much faster and easier to work with than my old site. I just choose the page, make the change.”</p>
                                </blockquote>
                            </div>

                            <div className="flex items-center mt-8">
                                <img className="flex-shrink-0 object-cover rounded-full w-11 h-11" src="https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-male-1.png" alt="" />
                                <div className="ml-4">
                                    <p className="text-base font-bold text-gray-900 font-pj">Leslie Alexander</p>
                                    <p className="mt-0.5 text-sm font-pj text-gray-600">Freelance React Developer</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col overflow-hidden shadow-xl">
                        <div className="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7">
                            <div className="flex-1">
                                <div className="flex items-center">
                                    <svg className="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path
                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                        />
                                    </svg>
                                    <svg className="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path
                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                        />
                                    </svg>
                                    <svg className="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path
                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                        />
                                    </svg>
                                    <svg className="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path
                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                        />
                                    </svg>
                                    <svg className="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path
                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                        />
                                    </svg>
                                </div>

                                <blockquote className="flex-1 mt-8">
                                    <p className="text-lg leading-relaxed text-gray-900 font-pj">“Simply the best. Better than all the rest. I’d recommend this product to beginners and advanced users.”</p>
                                </blockquote>
                            </div>

                            <div className="flex items-center mt-8">
                                <img className="flex-shrink-0 object-cover rounded-full w-11 h-11" src="https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-male-2.png" alt="" />
                                <div className="ml-4">
                                    <p className="text-base font-bold text-gray-900 font-pj">Jacob Jones</p>
                                    <p className="mt-0.5 text-sm font-pj text-gray-600">Digital Marketer</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col overflow-hidden shadow-xl">
                        <div className="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7">
                            <div className="flex-1">
                                <div className="flex items-center">
                                    <svg className="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path
                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                        />
                                    </svg>
                                    <svg className="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path
                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                        />
                                    </svg>
                                    <svg className="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path
                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                        />
                                    </svg>
                                    <svg className="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path
                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                        />
                                    </svg>
                                    <svg className="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path
                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                        />
                                    </svg>
                                </div>

                                <blockquote className="flex-1 mt-8">
                                    <p className="text-lg leading-relaxed text-gray-900 font-pj">“I cannot believe that I have got a brand new landing page after getting Omega. It was super easy to edit and publish.”</p>
                                </blockquote>
                            </div>

                            <div className="flex items-center mt-8">
                                <img className="flex-shrink-0 object-cover rounded-full w-11 h-11" src="https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-female.png" alt="" />
                                <div className="ml-4">
                                    <p className="text-base font-bold text-gray-900 font-pj">Jenny Wilson</p>
                                    <p className="mt-0.5 text-sm font-pj text-gray-600">Graphic Designer</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
                </section> */}

            {/* {alert(phone2)} */}
            <FooterTwo phone={phone2} />
            {/* Start Back To Top */}
            <div className="backto-top" onClick={() => window.open(`https://api.whatsapp.com/send?phone=${phone2}&text=Hi I want to get ID!.`, "_blank")}>
                <ScrollToTop showUnder={160} style={{ backgroundColor: 'green' }}>
                    <FaWhatsapp fill='white' />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

        </div >
    )
}

export default PortfolioLanding;
